import React from 'react';

function UserCounter() {
  return (
    <>
      <div className="row py-md-3">
        <div className="col-lg-3 col-6 d-md-block d-flex align-items-end justify-content-center text-center">
          <div className="py-md-0 py-3">
            <img src="/images/home/traced-01.svg" alt="downloads" className="mb-2" width={70} height={80} />
            <h3 className="m-0 fw-600 text-white fs-25">9043+</h3>
            <p className="mb-0 fs-25 text-white">App Downloaded</p>
          </div>
        </div>
        <div className="col-lg-3 col-6 d-md-block d-flex align-items-end justify-content-center text-center">
          <div className="py-md-0 py-3">
            <img src="/images/home/traced-02.svg" alt="users" className="mb-2" width={70} height={80} />
            <h3 className="m-0 fw-600 text-white fs-25">1000</h3>
            <p className="mb-0 fs-25 text-white">Active User</p>
          </div>
        </div>
        <div className="col-lg-3 col-6 d-md-block d-flex align-items-end justify-content-center text-center">
          <div className="py-md-0 py-3">
            <img src="/images/home/traced-03.svg" alt="employees" className="mb-2" width={70} height={80} />
            <h3 className="m-0 fw-600 text-white fs-25">52+</h3>
            <p className="mb-0 fs-25 text-white">Employees</p>
          </div>
        </div>
        <div className="col-lg-3 col-6 d-md-block d-flex align-items-end justify-content-center text-center">
          <div className="py-md-0 py-3">
            <img src="/images/home/traced-04.svg" alt="customer" className="mb-2" width={70} height={80} />
            <h3 className="m-0 fw-600 text-white fs-25">99%</h3>
            <p className="mb-0 fs-25 text-white">Customer Satisfaction</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default UserCounter;
